import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./components/Routes";

function App() {
  return (
    
    <div className="App">
      <BrowserRouter >
      <PublicRoutes />
      </BrowserRouter>
    
     
    </div>
    
  );
  }

export default App;
