import { useState } from "react";
import { NavLink } from "react-router-dom";
import { logo } from "../asset";

const Navbar = () => {
  const [drawNav, setDrawNav] = useState(false);

  return (
    <div className="nav-bar-div">
      <nav>
        <div className="logo-container">
          <img className="navbar-brand-image" src={logo} alt="" />
          <img className="navbar-brand-image2" src={logo} alt="" />
        </div>
        <div className="nav-container">
          <ul className="nav-items">
            <NavLink to="/browse">
              <li>
                <div className="nav-i">Browse</div>
              </li>
            </NavLink>
            <NavLink to="/login">
              <li>
                <div className="nav-i">Create a Campaign</div>
              </li>
            </NavLink>
            <NavLink to="/learn">
              <li>
                <div className="nav-i">learn</div>
              </li>
            </NavLink>
            <NavLink to="/login">
              <li>
                <div className="nav-i">Login</div>
              </li>
            </NavLink>
            <NavLink to="/signup">
              <li>
                <button className="signup">Sign Up</button>
              </li>
            </NavLink>
            <div
              onClick={() => {
                setDrawNav(!drawNav);
              }}
              className="menu-div"
            >
              <svg
                className="menu"
                viewBox="0 0 48 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 32H0V26.6667H24V32ZM48 18.6667H0V13.3333H48V18.6667ZM48 5.33333H24V0H48V5.33333Z"
                  fill="#8F8F8F"
                />
              </svg>
            </div>
          </ul>
        </div>
      </nav>

      {drawNav && (
        <div className="draw-bar">
          <div className="draw-bar-container">
            <NavLink to="/browse">
              <div className="draw-item">Browse</div>
            </NavLink>
            <NavLink to="/login">
              <div className="draw-item">Create a Campaign</div>
            </NavLink>
            <NavLink to="/learn">
              <div className="draw-item">Learn</div>
            </NavLink>
            <NavLink to="/login">
              <div className="draw-item">Log In</div>
            </NavLink>
            <NavLink to="/signup">
              <div className="draw-item">Sign Up</div>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
