import React from "react";
import { Routes, Route } from "react-router-dom";
import Browse from "./Browse/Browse";
import BrowseRoute from "./Browse/BrowseRoute";
import Home from "./Home/Home";
import Learn from "./Learn/Learn";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/browse" element={<Browse />} />
      <Route path="/" element={<Home />} />
      <Route path="/browse/browse-category" element={<BrowseRoute />} />

      <Route path="/learn" element={<Learn />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
    </Routes>
  );
};

export default PublicRoutes;
