import React from "react";

const Feature = () => {
  return (
    <div className="feature div">
      <div className="feature-header">
        <div className="feature-heading">
          <p className="feature-heading-main">How to Raise Capital</p>
        </div>
        <div className="feature-subheading">
          <p className="feature-subheading-main">
            Our model, technology, and team of in-house experts make us the most
            trusted platform in the crowdfunding industry. We leverage all the
            tools in our power to amplify Campaigns, so you can raise maximum
            awareness
          </p>
        </div>
      </div>
      <div className="feature-box-div">
        <div className="feature-boxes">
        <div className="feature-card">
            <div className="feature-card-in">
              <div>
                <img
                  className="feature-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/htw-01.png"
                  alt=""
                />
              </div>
              <div>
                <p className="feature-card-header">Create Campaign</p>
              </div>
              <div className="feature-card-subheader">
                <p className="feature-card-subhead-main">
                So you've got an idea? Draft your appealing 'Campaign' & submit.
                </p>
              </div>
            </div>
          </div>
          <div className="feature-card">
            <div className="feature-card-in">
              <div>
                <img
                  className="feature-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/htw-02.png"
                  alt=""
                />
              </div>
              <div>
                <p className="feature-card-header">Launch Campaign</p>
              </div>
              <div className="feature-card-subheader">
                <p className="feature-card-subhead-main">
                Allow us some time to review/moderate your Campaign or let us provide feedback.
                </p>
              </div>
            </div>
          </div>
          <div className="feature-card">
            <div className="feature-card-in">
              <div>
                <img
                  className="feature-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/htw-03.png"
                  alt=""
                />
              </div>
              <div>
                <p className="feature-card-header">Promote to Network</p>
              </div>
              <div className="feature-card-subheader">
                <p className="feature-card-subhead-main">
                Tell your crowd about Campaign and share via social networking channels.


                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Feature;
