import React from "react";

const BrowseCategory = () => {
  return (
    <div className="category-sec-div">
      <div className="category-sec-inner">
        <div className="category-sec-content">
          <div>
            <p className="browse-category-title">categories</p>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default BrowseCategory;
