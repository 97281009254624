import React from "react";

const Emaildiv = () => {
  return (
    <div className="email-div">
      <div className="email-inner-div">
        <div className="email-left-div">
          <div className="email-title-div">
            <p className="email-title-main">Stay connected with Equity</p>
          </div>
          <div className="email-subtitle-div"></div>
          <p className="email-subtitle-main">
            Receive new offerings, events, news and more directly into your
            inbox.
          </p>
        </div>
        <div className="email-right">
        <div className="email-right-div">
          <div className="email-input-div">
            <div className="email-input">
              <input 
              className="email-input-main"
              type="email" 
              placeholder="Enter your Email" />
            </div>
            <div className="email-button">
                <button className="email-button-main">
                    Join Now
                </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Emaildiv;
