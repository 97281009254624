import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="footer-list">
          <div className="footer-list-item">
            <div className="heading-footer">
              <p className="footer-titles">ABOUT</p>
            </div>
            <div className="list-footer">
              <ul className="footer-ul">
                <li>
                  <a className="foot-list-style" href="!#">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Help Center
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Faq
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-list">
          <div className="footer-list-item">
            <div className="heading-footer">
              <p className="footer-titles">QUICK LINKS</p>
            </div>
            <div className="list-footer">
              <ul className="footer-ul">
                <li>
                  <a className="foot-list-style" href="!#">
                    Bank Terms And Condition
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Fees
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Press
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-list">
          <div className="footer-list-item2">
            <div className="heading-footer2">
              <p className="hide-class"></p>
            </div>
            <div className="list-footer">
              <ul className="footer-ul2">
                <li>
                  <a className="foot-list-style" href="!#">
                    Career
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Help{" "}
                  </a>
                </li>
                <li>
                  <a className="foot-list-style" href="!#">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-list">
          <div className="footer-list-item">
            <div className="heading-footer">
              <p className="footer-titles">LET'S SOCIALIZE</p>
            </div>
            <div className="social-icon-div">
              <div className="social-icon-div1">
                <a
                  href="https://www.facebook.com/searchingyard"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f footer-icon"></i>
                </a>
              </div>
              <div className="social-icon-div1">
                <a
                  href="https://www.instagram.com/searchingyard/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram footer-icon"></i>
                </a>
              </div>
              <div className="social-icon-div1">
                <i className="fab fa-whatsapp footer-icon"></i>
              </div>
              <div className="social-icon-div1">
                <a
                  href="https://www.youtube.com/channel/UCIm8hFwJVuiJbRe8S4ghiCQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube footer-icon"></i>
                </a>
              </div>
              <div className="social-icon-div1">
                <a
                  href="https://www.linkedin.com/company/searchingyardindia/mycompany/verification"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in footer-icon"></i>
                </a>
              </div>
              <div className="social-icon-div1">
                <a
                  href="https://twitter.com/yardsearching"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter footer-icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-div">
        <div className="copyright-container">
          <div>
            <p className="copyright-p">
              Yard Fund Raising ©{new Date(Date.now()).getFullYear()} All Rights
              Reserved.
            </p>
          </div>
          <div>
            <p className="copyright-p">
              <span className="footer-cpy-rights">
                Designed and Developed by
              </span>{" "}
              <span className="sy-web">
                <a
                  href="https://searchingyard.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="sy-link-web"
                >
                  SearchingYard Software Private Limited
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
