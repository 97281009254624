import React, { useEffect, useState } from "react";
import Header from "./Home/Header";
import Navbar from "./Navbar";
const backImages = [
  "https://crowdfundingscript.com/equity_classic/upload/dynamic/category_69953.png",
  "https://crowdfundingscript.com/equity_classic/upload/dynamic/category_33914.png",
];
const Main = () => {
  const [img, setImg] = useState(0);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (img === backImages.length - 1) {
        setImg(0);
      } else {
        setImg(img + 1);
      }
    }, 6000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [img]);
  console.log(img);
  console.log(backImages[0]);
  return (
    <div
      className="main-div"
      style={{ backgroundImage: `url(${backImages[img]})` }}
    >
      <Navbar />
      <Header
        arraylength={backImages.length}
        currentImg={img}
        setCurrentImg={setImg}
      />
    </div>
  );
};

export default Main;
