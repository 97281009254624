import React from 'react'
import BrowseCategory from './BrowseCategory'

const BrowseRoute = () => {
  return (
    <div>
        <BrowseCategory />
    </div>
  )
}

export default BrowseRoute