import React from 'react';

const LearnCards = () => {
  return (
  <div className='learn-cards-div'>
      <div className="learn-cards-main">
      <div className="learn-cards-inner">
          <div className="learn-card">
              <div className="learn-card-icon">
                  <div className="learn-card-icon-main">
                  <i className="far fa-lightbulb learn-icon"></i>
                  </div>
              </div>
              <div className="learn-card-content">
                  <div className="learn-card-title">
                      <p>Knowledge Base</p>
                  </div>
                  <div className="learn-card-subtitle">
                      <p>25 Articles / 7 Categories</p>
                  </div>
              </div>
          </div>
          
      </div>
      <div className="learn-cards-inner">
          <div className="learn-card">
              <div className="learn-card-icon">
                  <div className="learn-card-icon-main">
                  <i className="fas fa-question learn-icon"></i>
                  </div>
              </div>
              <div className="learn-card-content">
                  <div className="learn-card-title">
                      <p>FAQ</p>
                  </div>
                  <div className="learn-card-subtitle">
                      <p>8 Questions : </p>
                  </div>
              </div>
          </div>
          
      </div>
      <div className="learn-cards-inner">
          <div className="learn-card">
              <div className="learn-card-icon">
                  <div className="learn-card-icon-main">
                  <i className="far fa-envelope learn-icon"></i>
                  </div>
              </div>
              <div className="learn-card-content">
                  <div className="learn-card-title">
                      <p>Contact Us</p>
                  </div>
                  <div className="learn-card-subtitle">
                      <p>Response within 24 hours</p>
                  </div>
              </div>
          </div>
          
      </div>
      </div>

  </div>
  );
};

export default LearnCards;
