import React from "react";
import { NavLink } from "react-router-dom";

const Header = ({ setCurrentImg, currentImg, arraylength }) => {
  const changeImg = () => {
    setTimeout(() => {
      if (currentImg === arraylength - 1) {
        setCurrentImg(0);
      } else {
        setCurrentImg(currentImg + 1);
      }
    }, 100);
  };

  return (
    <div className="header">
      <div className="header-inner">
        <div className="1st"></div>
        <div className="hero">
          <div className="left-arrow-div">
            <i onClick={changeImg} className="fas fa-chevron-left arrow1"></i>
          </div>
          <div className="hero-title-container">
            <div className="hero-headline">
              <p className="hero-heading">Invest in Companies You Believe In</p>
            </div>
            <div className="her-subline">
              <p className="hero-subheading">
                Invest in highly vetted startups & growth companies you love
              </p>
            </div>
            <div className="button-place">
              <NavLink to="/signup">
                <button className="button">Become an Investor</button>
              </NavLink>
            </div>
          </div>
          <div className="right-arrow-div arrow1">
            <i onClick={changeImg} className="fas fa-chevron-right"></i>
          </div>
        </div>
        <div className="2nd"></div>
      </div>
    </div>
  );
};

export default Header;
