import React from "react";
import { NavLink } from "react-router-dom";

const BrowseHeader = () => {
  return (
    <div className="browse-header-div">
      <div className="dummy-div">
        <div className="browse-header-inner">
          <NavLink to="/browse/browse-category">
            <div className="browse-title-box">
              <div>
                <p className="browse-title-main">All Categories</p>
              </div>
              <div className="browse-down-arrow">
                <img
                  className="down-arrow-cat"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/down-arrow.png"
                  alt="down"
                />
              </div>
            </div>
          </NavLink>
          <div className="browse-subtitle-box">
            <div>
              <p className="subtitle-start">sorted By</p>
            </div>
            <span className="side-browse-div">
              <div>
                <p className="browse-subtitle-main">Latest Campaigns</p>
              </div>
              <div className="browse-down-arrow-sub">
                <img
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/down-arrow.png"
                  alt="down"
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowseHeader;
