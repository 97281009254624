import React from 'react';
import Footer from '../Footer';
import Navbar2 from '../Navbar2';
import LoginBanner from './LoginBanner';

const Login = () => {
  return (
  <>
  <Navbar2 />
  <LoginBanner />
  <Footer />
  </>
  );
};

export default Login;
