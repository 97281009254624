import React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const LoginBanner = () => {
  const [display, setDisplay] = useState(false);
  return (
    <div className="login-banner">
      <div className="login-inner">
        <div className="login-inner-main">
          <div className="login-inner-box">
            <div className="login-left-box">
              <img
                className="login-img"
                src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/login-icon.png"
                alt="pic"
              />
            </div>
            <div className="login-right-box">
              <form action="" className={display ? "d-none" : "d-flex"}>
                <div className="login-title">
                  <p className="login-title-main">Log In</p>
                </div>
                <div className="login-subtitle">
                  <p className="login-subtitle-main">
                    Don't have an account?{" "}
                    <span className="color-signup">Sign Up</span>
                  </p>
                </div>

                <div className="text-input">
                  {/* <input
                    type="email"
                    placeholder="Email Address"
                    className="login-email"
                  /> */}
                  <TextField
                    id="standard-basic"
                    placeholder="Email Address"
                    name="email"
                    className="login-email"
                    variant="standard"
                  />
                </div>
                <div className="text-input">
                  {/* <input type="password"
                placeholder="Password"
                 className="login-email" /> */}
                  <TextField
                    id="standard-basic2"
                    placeholder="Password"
                    type="password"
                    className="login-email"
                    variant="standard"
                  />
                </div>
                <div className="login-button-div">
                  <div className="login-button-inner">
                    <button className="login-button">Log In</button>
                  </div>
                  <div className="login-forget">
                    <p
                      className="forget-login"
                      onClick={() => {
                        setDisplay(!display);
                      }}
                    >
                      I forgot my Password
                    </p>
                  </div>
                </div>
                <div className="login-or">
                  <div className="left-or"></div>
                  <p className="main-or">or</p>
                  <div className="right-or"></div>
                </div>
                <div className="social-button-div">
                  <button className="login-social-button">
                    <div className="button-flex">
                      <div>
                        <svg
                          className="login-icon-main"
                          stroke="currentColor"
                          fill="none"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                          <polyline points="10 17 15 12 10 7"></polyline>
                          <line x1="15" y1="12" x2="3" y2="12"></line>
                        </svg>
                      </div>
                      <div>Social Log In</div>
                    </div>
                  </button>
                </div>
                <div className="login-footer-div">
                  <p className="login-footer-main">
                    We will never post anything without your permission.
                  </p>
                </div>
              </form>
              <div className={display ? "d-flex" : "d-none"}>
                <div className="forget-password">
                  <div className="forget-password-inner">
                    <div className="forget-container">
                      <p className="forget-para">Forgot your password?</p>
                    </div>
                    <div className="text-input">
                      {/* <input
                    type="email"
                    placeholder="Email Address"
                    className="login-email"
                  /> */}
                      <TextField
                        id="standard-basic"
                        placeholder="Email Address"
                        name="email"
                        className="login-email"
                        variant="standard"
                      />
                    </div>
                    <div className="forget-div-buttons">
                      <div>
                        <p
                          className="login-back-button"
                          onClick={() => {
                            setDisplay(!display);
                          }}
                        >
                          <ArrowBackIcon className="login-back-icon" />
                          Back To Login
                        </p>
                      </div>
                      <div className="reset-button-div">
                        <button className="reset-button-main">
                          Reset My Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
