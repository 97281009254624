import Footer from '../Footer';
import Navbar2 from '../Navbar2';
import SignupHeader from './SignupHeader';

const SignUp = () => {
  return (
  <div>
      <Navbar2 />
      <SignupHeader />
      <Footer />

  </div>
  );
};

export default SignUp;
