import React from 'react';
import Main from '../Main';
import Detail from './Detail';
import Pricediv from '../Pricediv';
import Funddiv from './Funddiv';
import Category from './Category';
import Feature from './Feature';
import Emaildiv from './Emaildiv';
import Footer from '../Footer';

const Home = () => {
  return (<div>
      <Main />
      <Detail />
      <Pricediv />
      <Funddiv />
      <Category />
      <Feature />
      <Emaildiv />
      <Footer />
  </div>);
};

export default Home;
