import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./style/Home/main.css";
import "./style/Home/detail.css";
import "./style/pricediv.css";
import "./style/Home/funddiv.css";
import "./style/Home/category.css";
import "./style/feature.css";
import "./style/Home/emaildiv.css";
import "./style/footer.css";
import "./style/navbar2.css";
import "./style/Browse/browse-header-div.css";
import "./style/Learn/learn-banner.css";
import "./style/Learn/learn-cards.css";
import "./style/Learn/learn-list.css";
import "./style/Login/login.css";
import "./style/Signup/signup.css";
import "./style/Browse/browse-category.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
