import { useNavigate, NavLink } from "react-router-dom";
import { useState } from "react";
import { logo } from "../asset";

const Navbar2 = () => {
  const navigate = useNavigate();
  const [displayNav, setDisplayNav] = useState(false);
  return (
    <div>
      <nav>
        <div className="logo-container2">
          <img
            className="navbar-brand-image3"
            onClick={() => navigate("/")}
            src={logo}
            alt=""
          />
        </div>
        <div className="nav-container2">
          <ul className="nav-items2">
            <li>
              <NavLink to="/browse">
                <div className="nav-i2">Browse</div>
              </NavLink>
            </li>
            <NavLink to="/login">
              <li>
                <div className="nav-i2">Create a Campaign</div>
              </li>
            </NavLink>
            <NavLink to="/learn">
              <li>
                <div className="nav-i2">Learn</div>
              </li>
            </NavLink>
            <NavLink to="/login">
              <li>
                <div className="nav-i2">Login</div>
              </li>
            </NavLink>
            <NavLink to="/signup">
              <li>
                <button className="signup2">Sign Up</button>
              </li>
            </NavLink>
            <svg
              className="menu2"
              onClick={() => {
                setDisplayNav(!displayNav);
              }}
              viewBox="0 0 48 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 32H0V26.6667H24V32ZM48 18.6667H0V13.3333H48V18.6667ZM48 5.33333H24V0H48V5.33333Z"
                fill="#8F8F8F"
              />
            </svg>
          </ul>
        </div>
      </nav>

      {displayNav && (
        <div className="draw-bar">
          <div className="draw-bar-container">
            <NavLink to="/browse">
              <div className="draw-item">Browse</div>
            </NavLink>
            <NavLink to="/login">
              <div className="draw-item">Create a Campaign</div>
            </NavLink>
            <NavLink to="/learn">
              <div className="draw-item">Learn</div>
            </NavLink>
            <NavLink to="/login">
              <div className="draw-item">Log In</div>
            </NavLink>
            <NavLink to="/signup">
              <div className="draw-item">Sign Up</div>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar2;
