import React from 'react';
import Footer from '../Footer';
import Navbar2 from '../Navbar2';
import LearnBanner from './LearnBanner';
import LearnCards from './LearnCards';
import LearnList from './LearnList';

const Learn = () => {
  return (
  <div>
      <Navbar2 />
      <LearnBanner />
      <LearnCards />
      <LearnList />
      <Footer />

  </div>
  );
};

export default Learn;
