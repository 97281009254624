import React from "react";

const Funddiv = () => {
  return (
    <div className="fund-container">
      <div className="inner-fund-div">
        <div className="fund-header">
          <p className="fund-heading">It's Easy to Get Funded with Equity</p>
        </div>
        <div className="fund-subhead">
          <p className="fund-subhead-para">
            Equity Get an indication of approval after a short, 4 step
            application process. We need property address, financials, your
            experience and contact information so we can follow up. Know
            immediately if we can help you fund your next property.
          </p>
        </div>
        <div className="fund-button-container">
            <button className="fund-button">Get Invested</button>
        </div>
      </div>
    </div>
  );
};

export default Funddiv;
