import React from 'react';

const LearnBanner = () => {
  return (
  <div className='learn-banner-div'>
      <div className="learn-title">
          <p className='learn-title-main'>Help Center</p>
      </div>

  </div>
  );
};

export default LearnBanner;
