import React from "react";

const LearnList = () => {
  return (
    <div className="learn-list-div">
      <div className="learn-list-inner">
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Contributing to a Campaign</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Creating a Campaign</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>Demo</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How To Add Media To Pitch Text</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Go Live</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>About Verifications</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How To Create a Campaign</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Fundraising Basics</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>Payment Types</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>International Campaigns</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Donations and Withdrawals</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>Stripe Fees (UK Accounts)</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How Do Withdraw Money</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How Long Do Withdrawals Take</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How Are Payments Processed</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>Private or Anonymous Donations</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Manage Your Contribution</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute for a Friend</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>Refunds</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-list-item">
          <div className="learn-list-main">
            <div className="learn-list-title">
              <p className="lists-title">Managing Your Campaign</p>
            </div>
            <div className="learn-list-lists">
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
              <div className="learn-lists-item1">
                <p className="lists-sublist"><i className="far fa-file-alt lists-icon"></i>How to Contribute Anonymously</p>
              </div>
            </div>
          </div>
        </div>
        

      </div>
    </div>
  );
};

export default LearnList;
