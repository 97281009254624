import React from "react";
import BrowseHeader from "./BrowseHeader";
import Navbar2 from "../Navbar2";
import Pricediv from "../Pricediv";
import Footer from "../Footer";

const Browse = () => {
  return (
    <>
      <Navbar2 />
      <BrowseHeader />
      <Pricediv />
      <Footer />
    </>
  );
};

export default Browse;
