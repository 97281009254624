import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";

const SignupHeader = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      retypeemail: "",
      password: "",
      retypepass: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required("First name and Last name are required.")
        .matches(
          "/^[a-zA-Z]+ [a-zA-Z]+$/",
          "First name and Last name are required."),
      phone: yup
        .string()
        .required("Enter phone Number.")
        .matches("(0|91)?[7-9][0-9]{9}", "Phone Number Not Valid")
        .max(10, "Maximum 10 Digit"),
      email: yup
        .string()
        .required("Email is Required.")
        .email("Enter valid email"),
      retypeemail: yup
        .string()
        .required("Retype Email is required.")
        .oneOf([yup.ref("email")], "Email does not match"),
      password: yup
        .string()
        .required("Password is required.")
        .oneOf([yup.ref("password"), null])
        .min(8, "Please choose a more secure password"),
      retypepass: yup
        .string()
        .required("Confirm Password is required.")
        .oneOf([yup.ref("password")], "Password must match."),
    }),
    onSubmit: (values) => {
      console.log("formvalue", values);
    },
  });

  return (
    <div className="signup-container">
      <div className="signup-inner">
        <div className="signup-title">
          <p className="signup-title-main">Sign Up</p>
        </div>
        <div className="signup-subtitle">
          <p className="signup-subtitle-main">
            Already have an account? <span className="login-color">Log In</span>
          </p>
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Full Name"
            className="login-email"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="standard"
            // inputProps={{
            //   classes: {
            //     root: "input-props-root",
            //     notchedoutline: "notched-textfield",
            //     input: "input-props-field",
            //   },
            // }}
          />
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Phone"
            className="login-email"
            variant="standard"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className="login-email"
            variant="standard"
          />
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Retype Email"
            name="retypeemail"
            value={formik.values.retypeemail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.retypeemail && Boolean(formik.errors.retypeemail)
            }
            helperText={formik.touched.retypeemail && formik.errors.retypeemail}
            className="login-email"
            variant="standard"
          />
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            className="login-email"
            variant="standard"
          />
        </div>
        <div className="text-input">
          <TextField
            id="standard-basic"
            placeholder="Retype Password"
            name="retypepass"
            value={formik.values.retypepass}
            className="login-email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.retypepass && Boolean(formik.errors.password)}
            helperText={formik.touched.retypepass && formik.errors.retypepass}
            variant="standard"
          />
        </div>
        <div className="checkbox-div">
          <div>
            <input className="check-box-hold" type="checkbox" />
          </div>
          <div className="checkbox-content">
            <p>
              {" "}
              By clicking Sign Up for Equity, you agree to our{" "}
              <span className="terms-div">Terms Of Use</span> .
            </p>
          </div>
        </div>
        <div className="signup-page-button">
          <button
            className="signup-page-button-main"
            type="submit"
            onSubmit={formik.handleSubmit}
          >
            Sign Up
          </button>
        </div>
        <div className="login-or">
          <div className="left-or"></div>
          <p className="main-or">or</p>
          <div className="right-or"></div>
        </div>
        <div className="social-button-div">
          <button className="login-social-button">
            <div className="button-flex">
              <div>
                <svg
                  className="login-icon-main"
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                  <polyline points="10 17 15 12 10 7"></polyline>
                  <line x1="15" y1="12" x2="3" y2="12"></line>
                </svg>
              </div>
              <div>Social Sign Up</div>
            </div>
          </button>
        </div>
        <div className="login-footer-div">
          <p className="login-footer-main">
            We will never post anything without your permission.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupHeader;
