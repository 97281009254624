import React from "react";
import { CARD1, CARD2, CARD3, ICON1, ICON2, ICON3 } from "../asset";

const Pricediv = () => {
  return (
    <div className="pricediv-container">
      <div className="pricediv-header">
        <div className="pricediv-heading">
          <p className="pricediv-heading-main">
            Current Investment Opportunities
          </p>
        </div>
      </div>
      <div className="price-card-div">
        <div className="price-card">
          <div
            className="price-card-image-div"
            style={{ backgroundImage: `url(${CARD1})` }}
          >
            <div
              className="small-image"
              style={{ backgroundImage: `url(${ICON1})` }}
            ></div>
          </div>
          <div className="price-card-content">
            <div className="price-card-title">
              <p className="price-card-title-main">Rockers Techn</p>
              <div className="price-card-location">
                <p className="price-card-location-main">
                  <i className="fas fa-map-marker-alt mr-1"></i> Vadodara, India
                </p>
              </div>
              <div className="progress-bar">
                <progress id="progress-file" value="4" max="100">
                  {" "}
                  100%{" "}
                </progress>
              </div>
            </div>
            <div className="price-card-price-div">
              <div className="price-card-sec1">
                <div className="sec1-price">
                  <p className="sec1-price-main">$300.00 USD</p>
                </div>
                <div className="sec1-subtitle">
                  <p className="sec1-subtitle-main">Raised</p>
                </div>
              </div>
              <div className="price-card-sec2">
                <div className="sec2-price">
                  <p className="sec2-price-main">$5,000,000.00 USD</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Goal</p>
                </div>
              </div>
              <div className="price-card-sec3">
                <div className="sec3-price">
                  <p className="sec3-price-main">309</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Days Left</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="price-card">
          <div
            className="price-card-image-div2"
            style={{ backgroundImage: `url(${CARD2})` }}
          >
            <div
              className="small-image2"
              style={{ backgroundImage: `url(${ICON2})` }}
            ></div>
          </div>
          <div className="price-card-content">
            <div className="price-card-title">
              <p className="price-card-title-main">Access Earth Travel Tech</p>
              <div className="price-card-location">
                <p className="price-card-location-main">
                  <i className="fas fa-map-marker-alt mr-1"></i> Vadodara, India
                </p>
              </div>
              <div className="progress-bar">
                <progress id="progress-file" value="0" max="100">
                  {" "}
                  100%{" "}
                </progress>
              </div>
            </div>
            <div className="price-card-price-div">
              <div className="price-card-sec1">
                <div className="sec1-price">
                  <p className="sec1-price-main">$0.00 USD</p>
                </div>
                <div className="sec1-subtitle">
                  <p className="sec1-subtitle-main">Raised</p>
                </div>
              </div>
              <div className="price-card-sec2">
                <div className="sec2-price">
                  <p className="sec2-price-main">$2,000,000.00 USD</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Goal</p>
                </div>
              </div>
              <div className="price-card-sec3">
                <div className="sec3-price">
                  <p className="sec3-price-main">306</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Days Left</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="price-card">
          <div
            className="price-card-image-div3"
            style={{ backgroundImage: `url(${CARD3})` }}
          >
            <div
              className="small-image3"
              style={{ backgroundImage: `url(${ICON3})` }}
            ></div>
          </div>
          <div className="price-card-content">
            <div className="price-card-title">
              <p className="price-card-title-main">Rockers T Flexible Debt</p>
              <div className="price-card-location">
                <p className="price-card-location-main">
                  <i className="fas fa-map-marker-alt mr-1"></i> Vadodara, India
                </p>
              </div>
              <div className="progress-bar">
                <progress id="progress-file" value="12" max="100">
                  {" "}
                  100%{" "}
                </progress>
              </div>
            </div>
            <div className="price-card-price-div">
              <div className="price-card-sec1">
                <div className="sec1-price">
                  <p className="sec1-price-main">$700.00 USD</p>
                </div>
                <div className="sec1-subtitle">
                  <p className="sec1-subtitle-main">Raised</p>
                </div>
              </div>
              <div className="price-card-sec2">
                <div className="sec2-price">
                  <p className="sec2-price-main">$5,000.00 USD</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Goal</p>
                </div>
              </div>
              <div className="price-card-sec3">
                <div className="sec3-price">
                  <p className="sec3-price-main">9</p>
                </div>
                <div className="sec2-subtitle">
                  <p className="sec2-subtitle-main">Days Left</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricediv;
