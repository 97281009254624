import React from "react";

const Category = () => {
  return (
    <div className="categories">
      <div className="category-header">
        <p className="category-heading">Categories</p>
      </div>
      <div className="category-card-container">
        <div className="category-card-box">
          <div className="category-card">
            <div className="category-card-image"></div>
            <div className="category-title">
              <p className="category-main-title">Global Funds</p>
            </div>
            <div className="category-subhead">
              <p className="category-main-subhead">
                See Global Funds Listing{" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </p>
            </div>
          </div>
          <div className="category-card">
            <div className="category-card-image1"></div>
            <div className="category-title">
              <p className="category-main-title">Large Cap Equity Fund</p>
            </div>
            <div className="category-subhead">
              <p className="category-main-subhead">
                See Large Cap Equity Fund Listing{" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </p>
            </div>
          </div>
          <div className="category-card">
            <div className="category-card-image2"></div>
            <div className="category-title">
              <p className="category-main-title">Mid Cap Equity Fund</p>
            </div>
            <div className="category-subhead">
              <p className="category-main-subhead">
                See Mid Cap Equity Fund Listing{" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </p>
            </div>
          </div>
          <div className="category-card">
            <div className="category-card-image3"></div>
            <div className="category-title">
              <p className="category-main-title">Small Cap Equity Fund</p>
            </div>
            <div className="category-subhead">
              <p className="category-main-subhead">
                See Small Cap Equity Fund Listing{" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
