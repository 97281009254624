import React from "react";

const Detail = () => {
  return (
    <div className="detail">
      <div className="detail-header">
        <div className="detail-headline">
          <p className="detail-heading">What Is Equity Crowdfunding?</p>
        </div>
        <div className="detail-subheading">
          <p className="subhead-detail">
            Our model,technology, and team of in-house experts make us the most
            trusted platform in the crowdfunding industry. We leverage all the
            tools in our power to amplify Campaigns, so you can raise maximum
            awareness
          </p>
        </div>
      </div>
      <div className="detail-cards">
        <div className="detail-card-holder">
          <div className="card">
            <div className="card-in">
              <div>
                <img
                  className="detail-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/wec-01.png"
                  alt=""
                />
              </div>
              <div>
                <p className="detail-header">Find Enterpreneurs</p>
              </div>
              <div className="detail-subheader">
                <p className="subhead-para-detail">
                  Discover startups that are raising capital online.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-in">
              <div>
                <img
                  className="detail-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/wec-02.png"
                  alt=""
                />
              </div>
              <div>
                <p className="detail-header">Invest in Startups</p>
              </div>
              <div className="detail-subheader">
                <p className="subhead-para-detail">
                  Take a look at live investment opportunities today, and give
                  yourself a feel-good factor.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-in">
              <div>
                <img
                  className="detail-image"
                  src="https://crowdfundingscript.com/equity_classic/theme/classic/assets/images/equity/wec-03.png"
                  alt=""
                />
              </div>
              <div>
                <p className="detail-header">Follow Your Investments</p>
              </div>
              <div className="detail-subheader">
                <p className="subhead-para-detail">
                Manage & track your investments from your investor dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
